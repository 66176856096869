import { CSSProperties } from 'react';
import React from 'react';

export const loader = (
	<div className="spinner-chase">
		<div className="chase-dot" />
		<div className="chase-dot" />
		<div className="chase-dot" />
		<div className="chase-dot" />
		<div className="chase-dot" />
		<div className="chase-dot" />
	</div>
);

export const Loading: React.FC<{ style?: CSSProperties }> = ({ style }) => {
	return (
		<div id="preloader" style={style}>
			<div id="status">{loader}</div>
		</div>
	);
};
