import React, { useState } from 'react';
import {
	Row,
	Col,
	Alert,
	Card,
	CardBody,
	Container,
	FormFeedback,
	Input,
	Label,
	Form,
	CardTitle,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useForm } from '@mantine/form';
import UnauthenticatedApi from 'api/UnauthenticatedApi';
import { mapCodesToMessage } from 'types/ErrorCodes';
import { LoadingOverlay } from '@mantine/core';
import logo from 'assets/images/logoMainDark.svg';

const initialValues = {
	email: '',
};

const submitSuccessMessage =
	'Na twój adres e-mail wysłaliśmy informacje dotyczące resetowania	hasła. Postępuj zgodnie z instrukcjami zawartymi w wiadomości.';

export const ResetPassword: React.FC = () => {
	const form = useForm({
		initialValues,
	});

	const [submited, setSubmited] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleSubmit = async () => {
		setLoading(true);
		const response = await UnauthenticatedApi.requestResetPassword(
			form.values.email
		);
		form.reset();
		setLoading(false);

		if (!response)
			return form.setFieldError(
				'email',
				'Coś poszło nie tak! Spróbuj ponownie później'
			);

		if (response?.status === 201) return setSubmited(true);

		form.setFieldError(
			'email',
			mapCodesToMessage(response.data.insideExceptionCode)
		);

		// setErrorMessage(mapCodesToMessage(response.data.insideExceptionCode));
	};

	return (
		<div className="account-pages">
			<Container>
				<Row className="justify-content-center">
					<Col md={8} lg={6} xl={5}>
						<Card className="overflow-hidden">
							<LoadingOverlay visible={loading} />
							<div className="logo-bar">
								<div>
									<img
										src={logo}
										alt="company logo"
										className="img-fluid header-logo"
									/>
								</div>
							</div>
							<CardBody>
								<div className="p-2">
									<CardTitle>Zresetuj hasło</CardTitle>
									{submited && (
										<Alert color="success">{submitSuccessMessage}</Alert>
									)}
									<Form
										className="form-horizontal"
										onSubmit={form.onSubmit(handleSubmit)}
									>
										<div className="mb-3">
											<Label htmlFor="email" className="form-label">
												Email
											</Label>
											<Input
												required
												id="email"
												type="email"
												className="form-control"
												placeholder="Podaj adres email"
												{...form.getInputProps('email')}
												valid={submited}
												invalid={!!form.errors.email}
												disabled={submited}
											/>
											<FormFeedback valid type="valid">
												Podano prawidłowy adres email
											</FormFeedback>
											<FormFeedback valid={false} type="invalid">
												{form.errors.email}
											</FormFeedback>
										</div>
										<Row>
											<Col className="">
												<Link to="/login">
													<button
														type="button"
														className="btn btn-secondary w-md "
													>
														Wróć
													</button>
												</Link>
											</Col>
											<Col className="text-end">
												{!submited && (
													<button
														className="btn btn-primary w-md "
														type="submit"
													>
														Resetuj
													</button>
												)}
											</Col>
										</Row>
									</Form>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
