import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/index';

interface SliceState {
	loading: boolean;
	errorCode: string;
	headerRegistryLastCheckDate: number | null;
	headerTransfersLastCheckDate: number | null;
}

const initialState: SliceState = {
	loading: false,
	errorCode: '',
	headerRegistryLastCheckDate: localStorage.getItem(
		'headerRegistryLastCheckDate'
	)
		? Number(localStorage.getItem('headerRegistryLastCheckDate'))
		: null,
	headerTransfersLastCheckDate: localStorage.getItem(
		'headerTransfersLastCheckDate'
	)
		? Number(localStorage.getItem('headerTransfersLastCheckDate'))
		: null,
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.errorCode = action.payload;
		},
		setAppState: (state, action: PayloadAction<Partial<SliceState>>) => {
			Object.assign(state, action.payload);
		},
	},
});

export const { setLoading, setErrorCode, setAppState } = appSlice.actions;
export const selectAppState = (state: RootState) => state.app;
export default appSlice.reducer;
