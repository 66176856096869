import { ReactNode } from 'react';
import {
	CSSObject,
	MantineProvider as Mantine,
	MantineTheme,
	MultiSelect,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { loader } from 'components/partials/Loading';
import mantineStyles from 'assets/scss/custom/mantine.module.scss';
import 'dayjs/locale/pl';

interface ThemeComponent {
	defaultProps?: Record<string, any>;
	classNames?: Record<string, string>;
	styles?:
		| Record<string, CSSObject>
		| ((theme: MantineTheme, params: any) => Record<string, CSSObject>);
}

export const MantineProvider = ({ children }: { children: ReactNode }) => {
	const components: Record<string, ThemeComponent> = {
		Select: {
			classNames: {
				input: 'form-control',
				item: mantineStyles.selectItem,
				rightSection: mantineStyles.selectRightSection,
			},
		},
		LoadingOverlay: {
			defaultProps: {
				loader,
			},
		},
		Slider: {
			defaultProps: {
				color: '#2563eb',
				labelTransition: 'fade',
				labelTransitionDuration: 250,
			},
		},
		RangeSlider: {
			defaultProps: {
				color: '#2563eb',
				labelTransition: 'fade',
				minRange: 1,
				labelTransitionDuration: 250,
			},
		},
		Avatar: {
			classNames: {
				placeholder: mantineStyles.avatarPlaceholder,
			},
		},
		Popover: {
			defaultProps: {
				position: 'bottom-end',
				shadow: 'md',
				radius: '.4rem',
			},
			classNames: {
				dropdown: `${mantineStyles.popoverDropdown} popover`,
			},
		},
		DateRangePicker: {
			classNames: {
				input: 'form-control',
				weekday: mantineStyles.datePickerDay,
				day: mantineStyles.datePickerDay,
			},
		},
		DatePicker: {
			classNames: {
				input: 'form-control',
				weekday: mantineStyles.datePickerDay,
				day: mantineStyles.datePickerDay,
			},
		},
		Modal: {
			defaultProps: {
				overlayOpacity: 0.2,
			},
		},
		MultiSelect: {
			classNames: {},
		},
	};

	return (
		<Mantine
			theme={{
				datesLocale: 'pl',
				components,
				fontFamily: 'Poppins',
			}}
		>
			<ModalsProvider>{children}</ModalsProvider>
		</Mantine>
	);
};
