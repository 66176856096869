import axios from 'axios';
import { LoginValues } from 'pages/unauthenticated/Login';

const httpClient = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		Accept: 'application/json',
		'Content-type': 'application/json; charset=UTF-8',
	},
	validateStatus: (status) => status < 401,
});
class UnauthenticatedApi {
	static requestSMSCode = async ({
		email,
		password,
	}: Omit<LoginValues, 'smsCode'>) => {
		const response = await httpClient.post<{
			verification: boolean;
		}>('auth/verify_data', {
			email,
			password,
		});

		return response;
	};

	static login = async ({ email, password, smsCode }: LoginValues) => {
		const response = await httpClient.post('auth/login', {
			email,
			password,
			smsCode,
		});

		return response;
	};

	static refreshToken = async (refresh_token: string) => {
		const response = await httpClient.post('auth/refresh_token', {
			refresh_token,
		});
		return response;
	};

	static requestResetPassword = async (email: string) => {
		try {
			const response = await httpClient.post('reset_password/create_request', {
				email,
				redirectUrl: `${window.location.origin}/set-password`,
			});
			return response;
		} catch (error) {
			console.error(error);
			return undefined;
		}
	};

	static resetPassword = async (token: string, password: string) => {
		try {
			const response = await httpClient.post(
				`reset_password/process`,
				{
					token,
					password,
				},
				{ validateStatus: () => true }
			);
			return response;
		} catch (error) {
			console.error(error);
			return undefined;
		}
	};
}
export default UnauthenticatedApi;
