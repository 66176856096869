import { createRoot } from 'react-dom/client';
import { App } from './App';
import { AppProviders } from 'providers/AppProviders';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import './i18n';
import './assets/scss/theme.scss';
import 'react-image-crop/dist/ReactCrop.css';

if (process.env.NODE_ENV === 'production') {
	disableReactDevTools();
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<AppProviders>
		<App />
	</AppProviders>
);
